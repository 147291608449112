export const colors = [
  "#FFFFFF",
  "#DBD7D2",
  "#FFE5B4",
  "#FFCCCC",
  "#FBCEB1",
  "#BDB5D5",
  "#87CEEB",
  "#D2B48C",
  "#FFA6C9",
  "#FFFF00",
  "#FFAE42",
  "#9ACD32",
  "#5F9EA0",
  "#808080",
  "#FFA500",
  "#EE82EE",
  "#0D98BA",
  "#F75394",
  "#FF5349",
  "#FF2400",
  "#FF0000",
  "#3C69E7",
  "#008000",
  "#C71585",
  "#954535",
  "#8A2BE2",
  "#A52A2A",
  "#2A52BE",
  "#0000FF",
  "#4B0082",
  "#000000",
];
