<template>
  <v-container id="content-editor-view" fluid tag="section">
    <v-snackbar
      v-model="snackbarUpdate"
      timeout="2000"
      color="primary"
      top
      text
    >
      Content Saved!
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbarUpdate = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-row v-if="itemData">
      <v-col cols="12" class="mx-auto">
        <v-row justify="center">
         
          <v-col cols="10" md="10" class="action-container">
            <div class="actions-groups">
              <div class="actions-group">
                <p><b>SAVE</b> CHANGES &</p>
                <div class="actions">
                  <div>
                    <v-btn rounded @click="handlePrevious()">
                      GO TO PREVIOUS CARD
                    </v-btn>
                    <v-btn rounded @click="handleNext()">
                      GO TO NEXT CARD
                    </v-btn>
                  </div>
                  <div>
                    <v-btn rounded @click="updateContent(false)">
                      CONTINUE EDITING
                    </v-btn>
                    <v-btn rounded outline @click="updateContent(true)">
                     RETURN TO DECK  
                    </v-btn>
                  </div>
                </div>
              </div>
              <div class="actions-group">
                <p><b>DISCARD</b> CHANGES &</p>
                <div class="actions">
                  <v-btn rounded @click="confirmThenGoBack()">
                   DISCARD CHANGES &amp; RETURN
                  </v-btn>
                </div>
              </div>
            </div>
          </v-col>
           <v-col cols="4">
        
            <v-text-field label="Program Name" v-model="itemData.program.name" disabled></v-text-field>
          </v-col>
          <v-col cols="8" md="8">
            <v-text-field label="Success Card Name" v-model="itemData.name">
            </v-text-field>
          </v-col>

          <v-col cols="5" md="5">
            <previewer :itemData="itemData" :currentCoach="currentCoach" />
          </v-col>

          <v-col cols="7" md="7">
            <h3>Edit Success Card Elements</h3>
            <v-sheet rounded="">
              <v-card class="mx-auto" tile>
                <v-tabs v-model="tab" align-with-title grow>
                  <v-tabs-slider color="primary"></v-tabs-slider>

                  <v-tab> TEXT </v-tab>
                  <v-tab> LOGO </v-tab>
                  <v-tab> BACKGROUND </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <!-- Font Tab -->
                  <v-tab-item>
                    <v-list-item one-line>
                      <v-list-item-content>
                        <v-list-item-title>Content Text</v-list-item-title>
                        <v-list-item-subtitle>
                          <quill-editor
                            :class="`editor-box ${isDarkEditor && 'dark-box'}`"
                            :options="editorOption"
                            :preserveWhitespace="true"
                            v-model="itemData.text"
                            @ready="onQuillReady"
                          ></quill-editor>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item one-line>
                      <v-list-item-content>
                        <v-list-item-title>
                          <b>Font Family</b>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <v-select
                            :items="sortedFonts"
                            item-text="displayName"
                            item-value="fontName"
                            v-model="itemData.fontFamily"
                          >
                            <template slot="selection" slot-scope="data">
                              {{ data.item.displayName }}
                            </template>
                            <template slot="item" slot-scope="data">
                              <span
                                v-bind:style="
                                  'font-family:' + data.item.fontName
                                "
                              >
                                {{ data.item.displayName }}
                              </span>
                            </template>
                          </v-select>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item one-line>
                      <v-list-item-content>
                        <v-list-item-title><b>Font Size</b></v-list-item-title>
                        <v-list-item-subtitle>
                          <v-select
                            :items="fontSizes"
                            item-text="sizeName"
                            item-value="fontSize"
                            v-model="itemData.fontSize"
                          >
                          </v-select>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item one-line>
                      <v-list-item-content class="v-list-item-content">
                        <p><b>Text Vertical Alignment</b></p>
                        <v-select
                          v-model="itemData.textPositionY"
                          :items="gridValues.Y"
                          item-text="label"
                          item-value="value"
                          persistent-hint
                          single-line
                        ></v-select>
                      </v-list-item-content>
                    </v-list-item>
                  </v-tab-item>
                  <!-- Logo Tab -->
                  <v-tab-item>
                    <v-list-item-content>
                      &nbsp;&nbsp;
                      <v-checkbox
                        v-model="itemData.showLogo"
                        :label="`Show Coach Logo?`"
                      ></v-checkbox>
                    </v-list-item-content>

                    <v-list-item one-line>
                      <v-list-item-content class="v-list-item-content">
                        <v-list-item-title
                          ><b>Logo Vertical Alignment</b></v-list-item-title
                        >
                        <v-select
                          v-model="itemData.logoPositionY"
                          :items="gridValues.Y"
                          item-text="label"
                          item-value="value"
                          persistent-hint
                          single-line
                        ></v-select>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item one-line>
                      <v-list-item-content class="v-list-item-content">
                        <v-list-item-title
                          ><b>Logo Horizontal Alignment</b></v-list-item-title
                        >

                        <v-select
                          :items="gridValues.X"
                          item-text="label"
                          item-value="value"
                          persistent-hint
                          single-line
                          v-model="itemData.logoPositionX"
                        ></v-select>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item one-line> </v-list-item>
                  </v-tab-item>
                  <!-- Background Tab -->
                  <v-tab-item>
                    <v-list-item one-line>
                      <v-list-item-content>
                        <div>
                          <p>Background Color</p>
                          <div
                            class="color-box"
                            @click="clickOnColorElement('backgroundColor')"
                            :style="`background-color: ${itemData.backgroundColor}`"
                          />
                        </div>
                        <div class="color-boxes">
                          <div
                            class="color-box small-box"
                            @click="setSelectedColor(color, 'backgroundColor')"
                            v-for="(color, index) in colors"
                            :key="index"
                            :style="`background-color: ${color}`"
                          ></div>
                        </div>
                      </v-list-item-content>
                    </v-list-item>

                    <background-selector
                      :onRemoveBg="removeBg"
                      :onSave="save"
                      :options="{ currentCoach: currentCoach }"
                      :bgType="availableBgsType"
                      @switchedBgType="switchedBgType"
                      v-model="itemData.graphic"
                      :backgrounds="getBgs"
                    />
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </v-sheet>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { colors } from "../utils/colors";
import { quillEditor } from "vue-quill-editor";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import Previewer from "../components/GraphicEditor/Previewer.vue";
import BackgroundSelector from "../components/GraphicEditor/BackgroundSelector.vue";

export default {
  name: "ContentEditorView",
  components: { quillEditor, Previewer, BackgroundSelector },

  data: function () {
    return {
      sortedFonts: [],
      hasChangedContent: false,
      hasUpdate: false,
      availableBgsType: "coach",
      colors,
      tab: null,
      snackbarUpdate: false,
      selectedGraphic: null,
      programItemIds: [],
      activeIndex: 0,
      gridValues: {
        X: [
          {
            label: "Left",
            value: "xLeft",
          },
          {
            label: "Center",
            value: "xCenter",
          },
          {
            label: "Right",
            value: "xRight",
          },
        ],
        Y: [
          {
            label: "Top",
            value: "yStart",
          },
          {
            label: "Middle",
            value: "yCenter",
          },
          {
            label: "Bottom",
            value: "yEnd",
          },
        ],
      },

      colorsBank: [
        "#0000ff",
        "#000000",
        "#a52a2a",
        "#008000",
        "#ffa500",
        "#ff0000",
        "#ee82ee",
        "#ffff00",
        "#ffa6c9",
        "#0D98BA",
        "#8a2be2",
        "#FF5349",
        "#C71585",
        "#ffffff",
        "#9ACD32",
        "#FFAE42",
        "#fbceb1",
        "#3c69e7",
        "#2a52be",
        "#808080",
        "#9ACD32",
        "#4b0082",
        "#ff2400",
        "#f75394",
        "#5F9EA0",
        "#954535",
        "#FFCCCC",
        "#FFE5B4",
        "#87ceeb",
        "#D2B48C",
        "#dbd7d2",
        "#BDB5D5",
      ],
      quillInstance: null,
      isDarkEditor: false,
    };
  },
  computed: {
    ...mapGetters({
      getBgs: "allBgs",
      fonts: "fonts",
      fontSizes: "fontSizes",
      currentCoach: "loggedInCoach",
      itemData: "getCurrentProgramItem",
      programItems: "getProgramItems",
    }),
  },
  methods: {
    handlePrevious() {
      this.updateContent(false);
      const contentId = this.$route.params.contentId;
      const programId = this.$route.params.programId;
      const index = this.programItemIds.indexOf(contentId);
      const previousIndex =
        index === 0 ? this.programItemIds.length - 1 : index - 1;
      const previousProgramItemID = this.programItemIds[previousIndex];
      this.$router.push(
        `/app/programs/${programId}/content/${previousProgramItemID}/edit`
      );
    },
    handleNext() {
      this.updateContent(false);
      const contentId = this.$route.params.contentId;
      const programId = this.$route.params.programId;
      const index = this.programItemIds.indexOf(contentId);
      const nextIndex = index < this.programItemIds.length - 1 ? index + 1 : 0;
      const nextProgramItemID = this.programItemIds[nextIndex];
      this.$router.push(
        `/app/programs/${programId}/content/${nextProgramItemID}/edit`
      );
    },
    onQuillReady(quill) {
      this.quillInstance = quill;
    },
    switchedBgType(type) {
      this.availableBgsType = type;
    },
    isSelectedClass(n) {
      console.log("iss", this.itemData, n);
      return true;
      //return this.itemData.graphic == n ? ' selectedBackground': '';
    },
    clickOnColorElement(inputName) {
      this.$refs[inputName].$el.click();
    },
    ...mapActions({
      update: "updateProgramContentItem",
      loadStockBgs: "getStockBackgrounds",
      loadCoachBgs: "getCoachBackgrounds",
      load: "getOneProgramContentItem",
      createProgram: "addProgramContentItem",
      axProgramItem: "axProgramItem",
      axResetCurrentProgram: "axResetCurentProgram",
      save: "uploadCoachBackground",
      removeBg: "deleteCoachBackground",
      setSelectedColor: ""
    }),
    ...mapMutations({
      resetProgramItem: "resetCurrentProgramItem",
    }),
    setSelectedColor(color, type){
      this.itemData.backgroundColor = color;
      console.log(type)
    },
    updateContent(goBack) {
      if (this.itemData.textPositionY) {
        this.itemData.textPositionY = this.itemData.textPositionY.toString();
      }
      if (this.itemData.textPositionX) {
        this.itemData.textPositionX = this.itemData.textPositionX.toString();
      }
      if (this.itemData.logoPositionX) {
        this.itemData.logoPositionX = this.itemData.logoPositionX.toString();
      }
      if (this.itemData.logoPositionY) {
        this.itemData.logoPositionY = this.itemData.logoPositionY.toString();
      }
      delete this.itemData.__typename;
      if (this.$route.params.contentId) {
        this.update({
          ...this.itemData,
          programId: this.$route.params.programId,
          id: this.$route.params.contentId,
        }).then(() => {
          const programItemId = this.$route.params.contentId;
          this.axProgramItem({ programItemId });
          this.snackbarUpdate = !goBack;
          if (goBack) {
            this.axResetCurrentProgram();
            this.back();
          }
        });
      }

      // if (this.$route.params.nextItemIndex && this.$route.query.contentType) {
      //   this.createProgram({
      //     ...this.itemData,
      //     programId: this.$route.params.programId,
      //     // graphic: this.selectedGraphic,
      //     order: parseInt(this.$route.params.nextItemIndex),
      //     contentType: this.$route.query.contentType,
      //   }).then((res) => {
      //     this.snackbarUpdate = !goBack;
      //     const contentID = res.createProgramItem.id;
      //     this.$router.push(
      //       `/app/programs/${this.$route.params.programId}/content/${contentID}/edit`
      //     );
      //     this.axProgramItem({ programItemId: contentID });
      //     this.sortedFonts = this.sortByKey(this.fonts, "displayName");
      //     if (goBack) {
      //       this.back();
      //     }
      //   });
      // }
    },
    back() {
      this.$router.push(`/app/programs/${this.$route.params.programId}`);
    },
    confirmThenGoBack() {
      if (this.hasUpdate) {
        if (confirm("Discard changes and leave this page? Are you sure ?")) {
          this.$router.push(`/app/programs/${this.$route.params.programId}`);
          this.hasUpdate = false;
        }
      } else {
        this.$router.push(`/app/programs/${this.$route.params.programId}`);
      }
    },
    setGraphicUrl(n) {
      this.selectedGraphic = n;
    },
    sortByKey(array, key) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
  },
  async mounted() {
    const programItemId = this.$route.params.contentId;
    let initialContentIds = [];
    let programContentIds = [];
    let adhocContentIds = [];

    this.programItems.map((item) => {
      switch (item.contentType) {
        case "Initial Content":
          initialContentIds.push(item.id);
          break;
        case "Program Content":
          programContentIds.push(item.id);
          break;
        case "Adhoc Content":
          adhocContentIds.push(item.id);
          break;
      }
    });

    this.programItemIds = initialContentIds.concat(
      programContentIds,
      adhocContentIds
    );
    if (programItemId) {
      await this.axProgramItem({ programItemId });
      this.sortedFonts = this.sortByKey(this.fonts, "displayName");
    }
  },
  created() {
    this.editorOption = {
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          [{ color: this.colorsBank }, { background: this.colorsBank }],
          [{ align: [] }],
          ["blockquote"],
          ["clean"],
        ],
      },
    };

    this.loadStockBgs();
    this.loadCoachBgs({ coachId: this.currentCoach.id });
  },
  unmounted() {
    this.resetProgramItem();
  },
  watch: {
    itemData: function () {
      this.hasUpdate = true;
    },
  },
};
</script>

<style scoped>
.v-input--switch {
  margin-top: 0;
}
.selected-bg {
  padding: 2px;
  border: solid 2px green;
}
.v-list-item-content :nth-child(2) {
  margin-left: 3px;
  margin-top: 1px;
}
.v-list-item-content p {
  color: rgb(77, 77, 77);
}
.small-box {
  width: 20px;
  height: 20px;
}
.editor-box {
  height: 410px;
  padding-bottom: 50px;
}

.ql-container {
  height: 169px;
}
.ql-align-right {
  text-align: right;
}
.ql-align-left {
  text-align: left;
}
.ql-align-center {
  text-align: center;
}
.ql-align-justify {
  text-align: justify;
}

.actions-groups {
  display: flex;
}

.actions-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border: 2px solid #555;
  margin-left: 20px;
}

.actions-group button {
  margin: 4px;
  min-width: 240px !important;
}

.action-container {
  display: flex;
  justify-content: flex-end;
}
</style>
