<template>
  <v-sheet class="mt-8 pa-2">
    <v-row>
      <v-col md="12">
        <div class="row">
          <div class="col">
            <h4>Select Graphic  <span v-if="value !=''"><v-btn outlined small @click="removeBg()">remove</v-btn></span></h4>
          </div>
          <div class="col">
            <div style="display: flex; justify-content: flex-end">
              <v-btn
                :outlined="bgType == 'coach'"
                rounded
                small
                @click="switchBgType('coach')"
                class="mr-3"
              >
                My Backgrounds
              </v-btn>

              <v-btn
                :outlined="bgType == 'stock'"
                rounded
                small
                @click="switchBgType('stock')"
                class="ml-3"
              >
                Stock Backgrounds
              </v-btn>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <v-btn
              small
              color="light"
              @click="choosePhoto()"
              class="float-right"
            >
              Upload New Background
            </v-btn>
            <input
              class="userBgUpload"
              truncate-length="15"
              type="file"
              accept="image/*"
              @change="onFilePicked"
              style="display: none"
            />
          </div>
        </div>
      </v-col>
      <template v-if="filteredBgs.length">
        <v-col
          v-for="(bg, n) in filteredBgs"
          :key="n"
          :class="`d-flex child-flex ${
            value == bg.url && 'selected-bg'
          } vimg-container`"
          cols="4"
          @click="setGraphicUrl(bg.url)"
        >
          <v-img
            :src="bg.url"
            :lazy-src="bg.url"
            aspect-ratio="1"
            class="grey lighten-2"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-btn
            v-if="bg.imageFor == 'coach'"
            @click="onRemoveBg({ id: bg.id, coachId: options.currentCoach.id })"
            icon
            small
            class="delete-btn"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-col>
      </template>
      <v-col cols="12" class="d-flex child-flex" v-if="!filteredBgs.length">
        <div class="note">
          <p>No Photos here yet.</p>
        </div>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import faker from "faker";

export default {
  props: ["backgrounds", "value", "bgType", "options", "onRemoveBg", "onSave"],
  emits: ["switchedBgType"],
  data() {
    return {
      selectedGraphic: null,
    };
  },
  computed: {
    filteredBgs() {
      return this.backgrounds
        ? this.backgrounds.filter((bgs) => bgs.imageFor == this.bgType)
        : [];
    },
  },
  methods: {
    choosePhoto() {
      document.querySelector(".userBgUpload").click();
    },
    onFilePicked(e) {
      const files = e.srcElement.files[0];
      if (files) {
        const fr = new FileReader();
        fr.readAsDataURL(files);
        fr.addEventListener("load", () => {
          this.onSave({
            coachId: this.options.currentCoach.id,
            name: faker.lorem.slug(),
            imageData: fr.result,
          });
        });
      }
    },
    switchBgType(type) {
      this.$emit("switchedBgType", type);
    },
    setGraphicUrl(url) {
     // console.log(url);
      this.$emit("input", url);
    },
    removeBg() {
    
      this.$emit("input", '');
    },
  },
};
</script>
<style lang="scss" scoped>
.vimg-container {
  position: relative;
  width: 207px;
}
.delete-btn {
  position: absolute;
  display: none;
}
.vimg-container:hover .delete-btn {
  display: block;
  background: white;
}
.note {
  width: 100%;
  padding: 10px 15px;
  p {
    text-align: center;
  }
}
.selected-bg {
  border: 4px solid goldenrod;
  box-shadow: 1px 7px 8px #ccc;
}
</style>
